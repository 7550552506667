import React from "react";
import {CompetitionMeta, UserStatistics} from "../../data-types";
import {
    Card, CardContent, CardHeader, Box, Avatar,
    Grid, Typography, Button, List, ListItemIcon,
    ListItem, ListItemText, Paper, Chip, useTheme,
    Divider
} from "@mui/material";
import {Link} from "react-router-dom";
import {RankIcon} from "../../icons";
import ReplyIcon from "@mui/icons-material/Reply";
import NotesIcon from "@mui/icons-material/Notes";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import BarChartIcon from '@mui/icons-material/BarChart';
import StarsIcon from '@mui/icons-material/Stars';
import {TypographyInlineLinks} from "../../components";

interface CompletedEventCardProps {
    competitionMeta: CompetitionMeta;
    userStatistics: UserStatistics | null;
    position?: number;
}

export function CompletedEventCard({competitionMeta, userStatistics, position}: CompletedEventCardProps) {
    const theme = useTheme();

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar>{position}</Avatar>
                }
                title={competitionMeta.name}
                titleTypographyProps={{
                    variant: "h5",
                    noWrap: true
                }}
                subheader={competitionMeta.paymentParticipant ?
                    `Event fee: $${(competitionMeta.paymentParticipant / 100).toFixed(2)}` :
                    ""
                }
            />
            <CardContent sx={{pt: 0}}>
                <Grid container spacing={2} alignItems="center">
                    {/*<Grid container item xs={12} md={7} justifyContent="space-around" alignItems="flex-start">*/}
                        <Grid item xs={12}>
                            {/*<Typography variant="body2" sx={{pl: 7}}>{competitionMeta.description}</Typography>*/}
                            {competitionMeta.paymentParticipant ?
                                <Button
                                    sx={{ml: 7}}
                                    startIcon={<ReplyIcon />}
                                    component={Link}
                                    variant="outlined"
                                    size="small"
                                    color="info"
                                    to={`/receipt/${competitionMeta.competitionId}`}
                                >
                                    Receipt
                                </Button> :
                                null
                            }
                            {/*<Box component="div" sx={{mt: 2, pl: 7}}>*/}
                            {/*    {competitionMeta.tags.map((c, i) => (*/}
                            {/*            <Chip*/}
                            {/*                sx={{mr: 2, bgcolor: theme.palette.primary.light}}*/}
                            {/*                variant="filled"*/}
                            {/*                label={c.name}*/}
                            {/*                key={i}*/}
                            {/*                size="small"*/}
                            {/*            />*/}
                            {/*        )*/}
                            {/*    )}*/}
                            {/*</Box>*/}
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <List sx={{pl: {xs: 0, sm: 0, md: 5}}}>
                                {competitionMeta.description ?
                                    <Paper elevation={2} sx={{mb: 1}}>
                                        <ListItem dense>
                                            <ListItemIcon>
                                                <NotesIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={competitionMeta.description}
                                                primaryTypographyProps={{variant: "subtitle2"}}
                                            />
                                        </ListItem>
                                    </Paper> :
                                    null
                                }
                                {competitionMeta.prizeDescription ?
                                    <Paper elevation={2} sx={{mb: 1}}>
                                        <ListItem dense>
                                            <ListItemIcon>
                                                <EmojiEventsIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                disableTypography
                                                sx={{whiteSpace: "pre-wrap"}}
                                                primary={
                                                    <TypographyInlineLinks text={competitionMeta.prizeDescription} variant="subtitle2" />
                                                }
                                            />
                                        </ListItem>
                                    </Paper> :
                                    null
                                }
                                {competitionMeta.roundsFinished ?
                                    <>
                                        <Paper elevation={2} sx={{mb: 1}}>
                                            <ListItem dense>
                                                <ListItemIcon>
                                                    <BarChartIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    disableTypography
                                                    primary={
                                                        <Typography variant="subtitle2">
                                                            {userStatistics ? userStatistics.currentScore.toFixed(2) : "N/A"}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </Paper>
                                        <Paper elevation={2} sx={{mb: 1}}>
                                            <ListItem dense>
                                                <ListItemIcon>
                                                    <StarsIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    disableTypography
                                                    primary={
                                                        <RankIcon rank={userStatistics ? userStatistics.percentile : 0.5} />
                                                    }
                                                />
                                            </ListItem>
                                        </Paper>
                                    </> :
                                    null
                                }
                            </List>
                        </Grid>
                    {/*</Grid>*/}
                    <Grid item xs={12} md={5}>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/myResults/${competitionMeta.competitionId}/reviewsOfMe`}
                        >
                            Reviews of Me
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/myResults/${competitionMeta.competitionId}/results`}
                        >
                            My Results
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/myResults/${competitionMeta.competitionId}/rankings`}
                        >
                            Current Rankings
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/leaders/${competitionMeta.competitionId}`}
                        >
                            View Leaders
                        </Button>
                    </Grid>
                    {competitionMeta.tags.length ?
                        <Grid item xs={12}>
                            <Divider sx={{my: 3}} />
                            <Box component="div" sx={{pl: {xs: 0, sm: 0, md: 7}}}>
                                <Typography variant="subtitle2" sx={{mb: 1}}>
                                    Tags:
                                </Typography>
                                {competitionMeta.tags.map((c, i) => (
                                        <Chip
                                            sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                            variant="filled"
                                            label={c.name}
                                            key={i}
                                            size="small"
                                        />
                                    )
                                )}
                            </Box>
                        </Grid> :
                        null
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}