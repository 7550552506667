import React, {useState} from "react";
import {Box, Paper, Typography, Button, Grid} from "@mui/material";
import {RedirectModal} from "../";
import {useTokenizedUrl} from "../../hooks";
import {toast} from "react-toastify";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export function WebcamError() {

    const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
    const {getTokenizedUrl} = useTokenizedUrl();

    const helpClick = () => {
        setRedirectModalOpen(true);
        getTokenizedUrl("/help/troubleshooting-common-issues")
            .then(tokenizedUrl => {
                window.open(tokenizedUrl);
            })
            .catch(e => {
                console.log(e);
                toast.error("There was an error in opening the external link.");
            })
            .finally(() => setRedirectModalOpen(false));
    }

    return (
        <>
            <Paper elevation={3} sx={{p: 5}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{textAlign: "center"}}>
                            Error:  Can't retrieve video and microphone.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            Tip:  Try refreshing the page in your browser and click 'Always Allow' when prompted for camera permissions.  If you still need help, please visit our troubleshooting page.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<HelpOutlineIcon />}
                            onClick={helpClick}
                        >
                            Help
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <RedirectModal
                open={redirectModalOpen}
                setOpen={setRedirectModalOpen}
                action={() => {}}
            />
        </>
    )

}