import {useCallback} from "react";
import {DataAccess} from "../../util";
export const useTokenizedUrl = () => {

    const getTokenizedUrl = useCallback(async (pagePath?: string) => {
        // https://collegenet.atlassian.net/wiki/spaces/S25Dev/pages/627179759/knowledgeowl+and+token+request
        let page: string = "";
        const tokenRequestPayload = {
            "token_request": {
                "token_id": "admin-focusring",
                "remote_system": "knowledgeowl",
                "token_type": "remote"
            }
        };
        const response = await DataAccess.post("/api/token_request.json", {data: tokenRequestPayload});
        const {base_url, token} = response.token_request;
        if (pagePath) {
            page = "&r=https://" + base_url + pagePath;
        }
        return "https://" + base_url + "/help/remote-auth?n=" + token + page;
    }, []);

    return {
        getTokenizedUrl: getTokenizedUrl
    }
}