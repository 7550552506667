import React, {PropsWithChildren} from "react";
import {Box, SxProps} from "@mui/material";

interface ModalBoxProps {
    sxOverrides?: SxProps;
}

export function ModalBox({sxOverrides, children}: PropsWithChildren<ModalBoxProps>) {

    const sx: SxProps = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    }

    return (
        <Box
            component="div"
            sx={{...sx, ...sxOverrides}}
        >
            {children}
        </Box>
    )
}