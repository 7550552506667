import {useState, useEffect} from "react";
import {RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";
import {PaymentSuccessDetails} from "./PaymentSuccessDetails";

export const usePaymentSuccess = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [paymentSuccessDetails, setPaymentSuccessDetails] = useState<PaymentSuccessDetails>({
        competitionId: NaN,
        name: "",
        description: "",
        prizeDescription: "",
        firstRoundStart: null,
        firstRoundEnd: null,
        paymentParticipant: NaN
    });

    useEffect(() => {
        const controller = new AbortController();

        const getDetails = async () => {
            const r = await DataAccess.get(`/api/competition/${competitionId}/getPaymentSuccessDetails.json`, {signal: controller.signal});
            setPaymentSuccessDetails(r.paymentSuccessDetails);
        }

        getDetails()
            .then(_ => {setRequestStatus("complete")})
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }

    }, [competitionId]);

    return {
        requestStatus: requestStatus,
        paymentSuccessDetails: paymentSuccessDetails
    }

}