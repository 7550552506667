import {DataAccess} from "../../util";
import {useState, useEffect} from "react";
import {RequestStatus} from "../../data-types";
import {useSearchParams, useNavigate} from "react-router-dom";
import qs from "qs";

export const useConfirmEmail = (confirmEmailToken: string | undefined) => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {
        const controller = new AbortController();

        const confirmEmail = async () => {
            if (confirmEmailToken) {
                await DataAccess.get(`/api/user/${confirmEmailToken}/confirmEmail.json`, {signal: controller.signal});
                setRequestStatus("complete");
            }
        }

        confirmEmail()
            .then(_ => {
                const params = qs.parse(searchParams.toString(), {ignoreQueryPrefix: true});
                if (params.eventId) {
                    navigate(`/enroll?eventId=${params.eventId}`);
                }
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort()
        }
    }, [confirmEmailToken, searchParams, navigate]);

    return requestStatus;
}